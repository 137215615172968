import {
  Card,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { getAuth } from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./Checklist.css";

function Checklist() {
  const { id } = useParams();

  const [state, setState] = React.useState({
    checked: [],
    checklist: [],
  });

  React.useEffect(() => {
    function handleFetch() {
      const ref = doc(db, "modules", id);
      getDoc(ref).then((res) => {
        setState((state) => ({
          ...state,
          checklist: res.data().checklist,
        }));
      });
    }

    function handleChecklistFetch() {

      const auth = getAuth(); 
      console.log(auth.currentUser.uid)
      const docRef = doc(db, 'users',  auth.currentUser.uid, 'modules', id); 
      getDoc(docRef).then((res) => {

        if (res.exists()) {
              setState((state) => ({
          ...state,
          checked: res.data().checked ? res.data().checked : [],
        }));
        }
    
      });
    }


    handleFetch();
    handleChecklistFetch()
  }, []);

  function handleCheck(ind, value) {
    console.log(value);
    let newArr = [...state.checked];
    newArr.splice(ind, 1, value);
    setState((state) => ({
      ...state,
      checked: newArr,
    }));
    const auth = getAuth(); 
    const docRef = doc(db, 'users',  auth.currentUser.uid, 'modules', id); 
    setDoc(docRef, {checked: newArr}, {merge: true}).then((e) => {
      console.log('success')
    }).catch((err) => {
      console.log(err)
    })


  }

  return (
    <Card className="Checklist">
      <h3 style={{ marginTop: "0px", marginBottom: "5px" }}>Checklist</h3>
      <List>
        {state.checklist.map((item, ind) => (
          <ListItem key={ind}>
            <Checkbox
          
               checked={state.checked[ind] ? state.checked[ind] : false}
              onClick={(e) => {
                handleCheck(ind, !state.checked[ind]);
              }} 
              size="medium"
              sx={{
                color: "#ade7b7",
                "&.Mui-checked": {
                  color: "#ade7b7",
                },
              }}
            />

            <ListItemText
               primary={
                <Typography className={state.checked[ind] ? "listitem-text" : ""}>
                  {item}
                </Typography>
              } 
            />
          </ListItem>
        ))}
      </List>
    </Card>
  );
}

export default Checklist;

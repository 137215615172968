import { Card } from "@mui/material";
import React from "react";
import "./Video.css";
function Video({obj}) {
  return (
    <Card className="video">

<div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={obj.url ? obj.url : ''}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>

    </Card>
  );
}

export default Video;

import React from "react";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import Funnel from "./Funnel";
import { getAuth } from "firebase/auth";
import { Card } from "@mui/material";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";

function Dashboard() {
  const auth = getAuth();
  const navigate = useNavigate();

  

  React.useEffect(() => {
    document.title = "Marketing Funnel | Home";
  }, []);

  const marketingQuotes = [
    {
      quote: "Marketing is no longer about the stuff you make but the stories you tell.",
      author: "Seth Godin"
    },
    {
      quote: "Your brand is a story unfolding across all customer touchpoints.",
      author: "Jonah Sachs"
    },
    {
      quote: "Don't be afraid to get creative and experiment with your marketing.",
      author: "Mike Volpe"
    },
    {
      quote: "Content is the atomic particle of all digital marketing.",
      author: "Rebecca Lieb"
    },
    {
      quote: "In today's information age of marketing and web 2.0, a company's website is the key to their entire business.",
      author: "Marcus Sheridan"
    },
    {
      quote: "People don't buy what you do; they buy why you do it.",
      author: "Simon Sinek"
    },
    {
      quote: "You can't sell anything if you can't tell anything.",
      author: "Beth Comstock"
    },
    {
      quote: "The best marketing doesn't feel like marketing.",
      author: "Tom Fishburne"
    },
    {
      quote: "Marketing's job is never done. It's about perpetual motion. We must continue to innovate every day.",
      author: "Beth Comstock"
    },
    {
      quote: "Content marketing is no longer a numbers game. It's a game of relevance.",
      author: "Jason Miller"
    },
    {
      quote: "Marketers need to build digital relationships and reputation before closing a sale.",
      author: "Chris Brogan"
    },
    {
      quote: "Marketing is really just about sharing your passion.",
      author: "Michael Hyatt"
    },
    {
      quote: "Great content is the best sales tool in the world.",
      author: "Marcus Sheridan"
    },
    {
      quote: "You can't out-spend your competition, but you can out-think and out-teach them.",
      author: "Jay Baer"
    },
    {
      quote: "Content marketing is a commitment, not a campaign.",
      author: "Jon Buscall"
    },
    {
      quote: "Marketing is a contest for people's attention.",
      author: "Seth Godin"
    },
    {
      quote: "Content is king, but marketing is queen, and runs the household.",
      author: "Gary Vaynerchuk"
    },
    {
      quote: "Marketers ruin everything. But we can fix it if we choose to.",
      author: "Gary Vaynerchuk"
    },
    {
      quote: "Your personal brand is your reputation. And your reputation in perpetuity is the foundation of your career.",
      author: "Gary Vaynerchuk"
    },
    {
      quote: "Do what you can't.",
      author: "Gary Vaynerchuk"
    },
    {
      quote: "The consumer is not a moron; she is your wife.",
      author: "David Ogilvy"
    },
    {
      quote: "If you can't explain it to a six-year-old, you don't understand it well enough yourself.",
      author: "Albert Einstein"
    },
    {
      quote: "The best marketing strategy ever: CARE.",
      author: "Gary Vaynerchuk"
    },
    {
      quote: "Success is no longer about your own efforts; it's the collective power of a community that matters.",
      author: "Alex Hormozi"
    },
    {
      quote: "The more value you provide, the more people you attract.",
      author: "Alex Hormozi"
    }
    // Add more quotes and authors as needed
  ];


  const [num, setNum] = React.useState(0); 
  

React.useEffect(() => {

    function generateRandom(maxLimit){
        let rand = Math.random() * maxLimit;
        rand = Math.floor(rand); // 99
        return rand;
      }

      function handleQouteSet () {
const rand = generateRandom(25);
setNum(rand)
      }

      handleQouteSet();
    
}, [])


const [state, setState] = React.useState({
  name: '', 
}); 

React.useEffect(() => {
  async function handleNameFetch () {
    const ref = doc(db, `/users/${auth.currentUser.uid}`); 
    await getDoc(ref).then((res) => {
      if (res.exists) {
        const name = (res.data().name ? res.data().name : ''); 
        const nameArr = name.split(' '); 
        const firstName = nameArr[0]; 


        setState((state) => ({
          ...state, 
          name: firstName,
        }))

      } else {
        console.log('no file')
      }

    }).catch((err) => {
      console.log(err)
    })

  }

  handleNameFetch(); 
}, [])




  



  return (

    <div className='dashboard'>
      <div className="welcome">

        <div className="welcome-msg">
                 {`Welcome, ${state.name}`}

        </div>
        
        
        
      
        </div>
        <div className='dash-left'>
            <Card className='dash-funnel-card'>
             <Funnel />
            </Card>
        </div>
        <div className='dash-right'>
            <Card className='dash-quoutes-card'>

                <p className='dash-quotes-p-q'>{`"${marketingQuotes[num].quote}"`}</p>
                <p className='dash-quotes-p-a'>{`~${marketingQuotes[num].author}`}</p>
               
            </Card>
            <Card className='dash-events-card'>
            <h3>Upcoming Events</h3>
            <div className='events-list'>
                <p> No Upcoming Events </p>
            </div>
            </Card>
        </div>
    </div>
    
    
  );
}
export default Dashboard;

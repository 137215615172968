import { ArrowDownward } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import Checklist from "./Checklist/Checklist";
import "./Module.css";
import Overview from "./Overview/Overview";
import Resources from "./Resources/Resources";
import Tasks from "./Tasks/Tasks";
import Video from "./Video/Video";

function Module() {
  // scrolling down to resources
  const scollToRef = useRef();
  const {id} = useParams(); 

  const [state, setState] = React.useState({
    title: '', 
  })

  React.useEffect(() => {
    function handleFetch () { 
      const ref = doc(db, 'modules', id); 
      getDoc(ref).then((res) => {
        document.title = res.data().title
        setState((state) => (res.data()))
      })
    } 
    handleFetch(); 
  }, [])
  
 
  function handlescroll () {


        scollToRef.current.scrollIntoView({behavior:"smooth"})

  
  }

  return (
    <div className="module">
      <div className="child">
        <div className="first">
          <Checklist />

          {state.url_active ?  <Video obj={state}/> : null}
         
        </div>
        <Overview />
        <Tasks />
        <div ref={scollToRef}> 
         <Resources />
        </div>
      
      </div>

      <div className="fabButton">
        <Fab onClick={((e) => {handlescroll()})} style={{ backgroundColor: "#ade7b7" }}>
          <ArrowDownward />
        </Fab>
      </div>
    </div>
  );
}

export default Module;

import { Autorenew, CheckCircle } from "@mui/icons-material";
import { Button, Card } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Modules.css";

import {
  getDocs,
  collection,
  where,
  query,
} from "firebase/firestore";
import { db } from "../../firebase";
import Completed from "./Completed/Completed";


function Modules() {
    const navigate = useNavigate(); 
    const {id} = useParams(); 

    const [state, setState] = React.useState({
      docs: []

    })

    React.useEffect(() => {
      function handleFetch () { 

        const citiesRef = collection(db, "modules");

        const collectionRef = query(citiesRef, where('category', '==', id), where('active', '==', true)); 
        getDocs(collectionRef).then((e) => {

          if (e.docs) {
              setState((state) => ({
            ...state, 
            docs: e.docs.map((doc) => ({...doc.data(), id: doc.id}))
          })) 
          } 
       
        })
      }
      handleFetch(); 
     
    }, [])
    

    
  return (
    <div className="Modules">
      <div className="modCard">


        {state.docs.map((item, ind) => (

             <Button  key={ind} style={{padding:'0px', height:'fit-content'}} onClick={((e) => {navigate(`/module/${item.id}`)})}>
            <Card className="item">
            <div className="topIcons">
                <div className="icons">

<Completed id={item.id} obj={item}/>



       {/*    <Autorenew /> */}
              
                </div>
          
            </div>
          <h3 className="title">{item.title}</h3>
          <div className="desc" dangerouslySetInnerHTML={{__html: item.overview}} />

          <div className="effectiveBar">

            <div className={item.efficiency < 1 ?  "effectiveItem blank" : "effectiveItem"}></div>
            <div className={item.efficiency < 2 ?  "effectiveItem blank" : "effectiveItem"}></div>
            <div className={item.efficiency < 3 ?  "effectiveItem blank" : "effectiveItem"}></div>
            <div className={item.efficiency < 4 ?  "effectiveItem blank" : "effectiveItem"}></div>
            <div className={item.efficiency < 5 ?  "effectiveItem blank" : "effectiveItem"}></div>
          </div>
          </Card>
        </Button>

        ))}






      </div>
    </div>
  );
}

export default Modules;

import { CardMembershipSharp, Help, Home, Logout, QuestionAnswer } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Header.css";
import {logout} from './firebase'
import { getAuth } from "firebase/auth";
import HomeIcon from '@mui/icons-material/Home';

function Header() {
  const navigate = useNavigate();
  const auth = getAuth(); 

  const [state, setState] = React.useState({
    open: false, 
  })

  const pathes = [
    {
        path: '/',
        title: 'How To Guide', 
        icon: <Help  fontSize='large'  style={{marginRight:"15px"}}/>, 
    },
    {
        path: '/payment',
        title: 'Subscription', 
        icon: <CardMembershipSharp  fontSize='large' style={{marginRight:"15px"}} />, 
    },
    {
      path: '/dashboard',
      title: 'Home', 
      icon: <HomeIcon  fontSize='large' style={{marginRight:"15px"}} />, 
  },

  ]

  

  if (auth.currentUser) {
return (
    <div className="Header">
      <div className="left">
        <IconButton
          onClick={(e) => {
            navigate("/dashboard");
          }}
          size="large"
        >
          <Home />
        </IconButton>
      </div>
      <div className="right">
        <h2>Marketing Funnel</h2>
        <IconButton size="large" onClick={((e) => {
            setState((state) => ({
                ...state, 
                open: !state.open
            }))
        })}>
          <MenuIcon />
        </IconButton>
      </div>

      <div className={state.open ? "nav-menu" : "nav-menu-closed"}>
         {pathes.map((path, ind) => (
          <div
            key={ind}
            className="logout"
            onClick={(e) => {
              navigate(path.path);
              setState((state) => ({
                ...state,
                open: !state.open,
              }));
            }}
          >
           {path.icon}
            <div className="text">{path.title}</div>
          </div>
        ))} 
        <div
            className="logout"
            onClick={(e) => {
             window.open('mailto:support@growtallmarketing.com')
            }}
          >
           <QuestionAnswer fontSize='large' style={{marginRight:"15px"}}/>
            <div className="text">{'Support'}</div>
          </div>
         <div
            className="logout"
            onClick={(e) => {
             logout().then((e) => {
              setState((state) => ({
                ...state,
                open: !state.open,
              }));
                navigate('/')
             })
            }}
          >
           <Logout fontSize='large' style={{marginRight:"15px"}}/>
            <div className="text">{'Sign Out'}</div>
          </div>
      </div>
    </div>
  );
  } else {
    return null; 
  }
  
}

export default Header;

import { Card } from '@mui/material'
import { doc, getDoc } from 'firebase/firestore'
import React from 'react'
import { useParams } from 'react-router-dom'
import { db } from '../../../firebase'
import './Overview.css'

function Overview() {
    const {id} = useParams(); 

    const [state, setState] = React.useState({
        overview: '', 
    })


    React.useEffect(() => {
        function handleFetch () { 
          const ref = doc(db, 'modules', id); 
          getDoc(ref).then((res) => {
    
            setState((state) => ({
              ...state, 
              overview: res.data().overview ? res.data().overview  : '',  
            }))
          })
        } 
        handleFetch(); 
      }, [])


  return (

    <Card className='overview'>
        <h3 className='title-msg'>Module Overview</h3>


        <div dangerouslySetInnerHTML={{ __html: state.overview }} />


    
    </Card>
  )
}

export default Overview
import { Download } from '@mui/icons-material'
import { Card, IconButton, List, ListItemButton, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { doc, getDoc } from 'firebase/firestore';
import React from 'react'
import { useParams } from 'react-router-dom';
import { db } from '../../../firebase';
import './Resources.css'

function Resources() {
    const {id} = useParams(); 

    const [state, setState] = React.useState({
      downloads: [], 
    })
  
    React.useEffect(() => {
      function handleFetch () { 
        const ref = doc(db, 'modules', id); 
        getDoc(ref).then((res) => {
  
          setState((state) => ({
            ...state, 
        downloads: res.data().downloads, 
          }))
        })
      } 
      handleFetch(); 
    }, [])
    
  return (
    <Card className='resources'>
        <h2 className='title'>Resources</h2>

        <List>

            {state.downloads.map((doc, ind) => (

                           <ListItemButton className='listitem' onClick={((e) => {window.open(doc.url)})} >
                <ListItemText primary={doc.name}/>
                <ListItemSecondaryAction>
                    <IconButton>
                        <Download />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItemButton>
            ))}
 

        </List>

    </Card>
   
  )
}

export default Resources
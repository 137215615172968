import styled from "@emotion/styled";
import {
  Alert,
  alpha,
  Button,
  Card,
  Dialog,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "../../firebase";
import "./Register.css";
import funnelLogo from "../Login/marketing-funnel-logo.png";
import blobOne from './Blobs/blobs-01.svg'
import blobTwo from './Blobs/blobs-02.svg'
import blobThree from './Blobs/blobs-03.svg'
import blobFour from './Blobs/blobs-04.svg'

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [emails, setEmails] = useState(true);
  const [sms, setSms] = useState(true);
  const [user, loading, error] = useAuthState(auth);

  const [state, setState] = React.useState({
    errOpen: false,
    errMsg: "",
    successOpen: false,
    successMsg: "",
    industry: "",
    business_name: "",
  });

  const navigate = useNavigate();

  const register = () => {
    if (
      email.length > 3 ||
      password.length > 3 ||
      name.length > 3 

    ) {
      registerWithEmailAndPassword(
        name,
        email,
        password,
      ).then((res) => {
        if (!res.success) {
          setState((state) => ({
            ...state,
            errOpen: true,
            errMsg: "Oops... Looks like something went wrong...",
            successMsg: "",
            successOpen: false,
          }));
        } else {
          setState((state) => ({
            ...state,
            errOpen: false,
            errMsg: "",
            successMsg: "Sucessfully created new user...",
            successOpen: true,
          }));
        }
        console.log(res);
      });
    } else {
      setState((state) => ({
        ...state,
        errOpen: true,
        errMsg: "Oops... Looks like you missed a few details...",
      }));
    }
  };
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
  }, [user, loading]);

  ///// Handle Dialog Open and Close \\\\\
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  
    ///// Handle Dialog Open and Close \\\\\
    const [Termsopen, setTermsopen] = React.useState(false);
    const handleTermsClose = () => {
      setTermsopen(false);
    };



  function DialogWindow() {
    return (
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md">
        <div className="terms-text">
          <p className="bold">
            Terms and Conditions for Grow Tall Marketing Funnel
          </p>

          <p>Last Updated: 23/9/2023</p>

          <p>
            Welcome to Grow Tall Marketing Funnel (&quot;the App&quot;) provided
            by Grow Tall Marketing, a company registered under the laws of
            Australia. By accessing or using the App, you agree to comply with
            and be bound by these Terms and Conditions. If you do not agree to
            these Terms and Conditions, please do not use the App.
          </p>

          <p className="bold">1. Use of the App</p>

          <p className="inset">
            1.1. Eligibility: You must be at least 18 years old or the legal age
            of majority in your jurisdiction to use the App.
          </p>
          <p className="inset">
            1.2. License: Grow Tall Marketing grants you a non-exclusive,
            non-transferable, revocable license to use the App for your personal
            or business use, subject to these Terms and Conditions.
          </p>

          <p className="bold">2. User Accounts</p>

          <p className="inset">
            2.1. Registration: To access marketing articles and resources, you
            are required to create a user account. You agree to provide
            accurate, current, and complete information during the registration
            process and to update such information to keep it accurate, current,
            and complete.
          </p>

          <p className="inset">
            2.2. Security: You are responsible for maintaining the
            confidentiality of your account login information and for all
            activities that occur under your account. You agree to notify us
            immediately of any unauthorized use of your account.
          </p>

          <p className="bold">3. Content</p>

          <p className="inset">
            3.1. Access to Resources: The App provides access to marketing
            articles and resources for informational purposes only.
          </p>

          <p className="inset">
            3.2. Prohibited Use: You agree not to use the App to distribute,
            post, or transmit any content that is illegal, defamatory,
            infringing, or violates the rights of any third party.
          </p>

          <p className="bold">4. Support</p>

          <p className="inset">
            4.1. Contact: If you have questions or require support, please
            contact our support team at support@growtallmarketing.com.
          </p>

          <p className="bold">5. Privacy</p>

          <p className="inset">
            5.1. Privacy Policy: Your use of the App is also governed by our
            <strong> Privacy Policy</strong>. By using the App, you consent to
            the collection and use of your personal information in accordance
            with our Privacy Policy.
          </p>

          <p className="bold">6. Termination</p>

          <p className="inset">
            6.1. Termination by Grow Tall Marketing: Grow Tall Marketing
            reserves the right to terminate or suspend your access to the App at
            any time, with or without cause, and with or without notice.
          </p>

          <p className="inset">
            6.2. Termination by You: You may stop using the App at any time.
          </p>

          <p className="bold">7. Disclaimers</p>

          <p className="inset">
            7.1. No Warranty: The App is provided &quot;as is&quot; and &quot;as
            available&quot; without any warranties of any kind, either express
            or implied, including, but not limited to, the implied warranties of
            merchantability, fitness for a particular purpose, or
            non-infringement.
          </p>

          <p className="inset">
            7.2. Limitation of Liability: To the maximum extent permitted by
            applicable law, Grow Tall Marketing shall not be liable for any
            indirect, incidental, special, consequential, or punitive damages,
            or any loss of profits or revenues, whether incurred directly or
            indirectly, or any loss of data, use, goodwill, or other intangible
            losses, resulting from your use or inability to use the App.
          </p>

          <p className="bold">8. Governing Law</p>

          <p className="inset">
            8.1. These Terms and Conditions are governed by and construed in
            accordance with the laws of Australia. You agree to submit to the
            exclusive jurisdiction of the courts located in Australia for the
            resolution of any disputes arising under these Terms and Conditions.
          </p>

          <p className="bold">9. Changes to Terms</p>

          <p className="inset">
            9.1. Grow Tall Marketing reserves the right to update or modify
            these Terms and Conditions at any time without prior notice. Your
            continued use of the App after any changes will constitute your
            acceptance of such changes.
          </p>

          <p className="bold">10. Contact Information</p>

          <p className="inset">
            10.1. If you have any questions or concerns about these Terms and
            Conditions, please contact us at support@growtallmarketing.com.
          </p>

          <p className="inset">
            By using the App, you acknowledge that you have read, understood,
            and agree to be bound by these Terms and Conditions.
          </p>
        </div>
      </Dialog>
    );
  }

  function TermsDialogWindow() {
    return (
      <Dialog onClose={handleTermsClose} open={Termsopen} fullWidth={true} maxWidth="md">
        <div className="terms-text">
        <p>Effective Date: 3rd Oct 2023<br  />
Thank you for using Grow Tall Marketing Funnel (&quot;App&quot;). Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and protect your data when you use our App.<br />
1. Information We Collect<br />
We may collect the following types of information:<br />
1.1 Personal Information: When you register or use our App, we may collect personal information such as your name, email address, and other contact information.<br />
1.2 Usage Information: We may collect information about how you use the App, including your interactions with the modules, the content you view, and other actions you take.<br  />
1.3 Device Information: We may collect information about the device you use to access the App, including your device's unique identifiers, IP address, br /owser type, and operating system.<br />
2. How We Use Your Information<br />
We may use the information we collect for the following purposes:<br />
2.1 Providing Services: To provide you with access to the modules and content offered by the App.<br />
2.2 Communication: To communicate with you about updates, changes, and important announcements related to the App.<br />
2.3 Improving the App: To analyse usage patterns and feedback to improve the functionality and user experience of the App.<br />
2.4 Legal Compliance: To comply with legal requirements and protect our rights and the rights of others.<br />
3. Data Sharing<br />
We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following circumstances:<br />
3.1 Service Providers: We may share your information with third-party service providers who help us operate and maintain the App.<br />
3.2 Legal Requirements: We may disclose your information if required by law or to protect our rights and interests.<br />
3.3 Business Transfers: If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.<br />
4. Security<br />
We take reasonable measures to protect your information from unauthorized access, disclosure, or alteration. However, please be aware that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your data.<br />
5. Your Choices<br />
You have the right to access, correct, or delete your personal information. You can make these requests by contacting us at support@growtallmarketing.com You can also unsubscribe from our communications at any time.<br />
6. Changes to this Privacy Policy<br />
We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the date at the top will indicate the latest revision.<br />
7. Contact Us<br />
If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at support@growtallmarketing.com<br />
By using our Web App, you agree to the terms and conditions outlined in this Privacy Policy.<br />
Grow Tall Marketing Funnel<br />
support@growtallmarketing.com</p>

<p>Australia.</p>
        </div>
      </Dialog>
    );
  }

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#ade7b7",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#ade7b7",
    },
  }));

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMsg: "",
      errorOpen: false,
      errorMsg: "",
    }));
  };

  const [vertical, setVertical] = React.useState("bottom");
  const [horizontal, setHorizontal] = React.useState("right");

  const businessIndustries = [
    "Information Technology",
    "Healthcare",
    "Finance",
    "Retail",
    "Manufacturing",
    "Hospitality",
    "Education",
    "Entertainment",
    "Real Estate",
    "Transportation",
    "Telecommunications",
    "Energy",
    "Agriculture",
    "Construction",
    "Automotive",
    "Consulting",
    "Food and Beverage",
    "Pharmaceuticals",
    "Advertising",
    "Media",
    "Environmental",
    "Legal",
    "Sports",
    "Travel and Tourism",
    "Non-profit",
    "Fashion",
    "Fitness",
    "E-commerce",
    "Gaming",
    "Biotechnology",
    "Aviation",
    "Insurance",
    "Engineering",
    "Art and Design",
    "Government",
    "Logistics",
    "Architecture",
    "Music",
    "Marketing",
    "Mining",
    "Renewable Energy",
    "Security",
    "Waste Management",
    "Forestry",
    "Forensics",
    "Space Exploration",
    "Social Media",
    "Shipping",
  ];

  return (
    <div className="register">
      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="success">
          {state.successMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="info">
          {state.errMsg}
        </Alert>
      </Snackbar>

      <DialogWindow />
      <TermsDialogWindow />

      <img src={blobTwo}  className='blob blobTwo'/>
      <img src={blobThree}  className='blob blobThree'/>

      <Card className="register__container">
        <img className="logo" src={funnelLogo} alt="logo" />

     

    

      

        <TextField
          type="text"
          size="small"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Full Name"
        />
        <TextField
          type="text"
          size="small"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email Address"
        />
      
        <TextField
          type="password"
          size="small"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />



        <Button
          style={{
            marginTop: "10px",
            backgroundColor: "#ade7b7",
            fontWeight: "bold",
            color: "black",
          }}
          onClick={register}
        >
          Register + Agree to Terms & Services
        </Button>

        <div>
          Already have an account? <Link to="/">Login</Link> now.
        </div>
        <div
          style={{ fontWeight: "600", color: "#ade7b7", cursor: "pointer" }}
          onClick={(e) => {
            setOpen(true);
          }}
        >
          Terms & Services.
        </div>
        <div
          style={{ fontWeight: "600", color: "#ade7b7", cursor: "pointer" }}
          onClick={(e) => {
            setTermsopen(true);
          }}
        >
          Privacy Policy.
        </div>
      </Card>
    </div>
  );
}
export default Register;

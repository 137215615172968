import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import Reset from "./Components/Reset/Reset";
import Dashboard from "./Components/Dashboard/Dashboard";
import Module from "./Components/Module/Module";
import Header from "./Header";
import Modules from "./Components/Modules Page/Modules";
import { AuthProvider } from "./Components/Context/AuthContext";
import Subscribe from "./Components/Subscription/Subscribe";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { auth } from "./firebase";
import PrivateRoutes from "./PrivateRoute";
import React from 'react'
import PaidRoutes from "./PaidRoutes";







function App() {

  const [stripePromise, setStripePromise] = React.useState(() => loadStripe("pk_live_51NsI9eJKTfIm5AjWZasOebdnjBqDFTpMKPnv2smGNnr2wa3SNTvA2EP8M34ErrfPZuPqGKtDtBPYZlCxQM7ziO3e00z9q6bx4P"))



  return (
    <div className="app">
      <Elements stripe={stripePromise} >
        <Router>
          <AuthProvider>
            <Header />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/reset" element={<Reset />} />

              <Route element={<PrivateRoutes />}>
               <Route element={<PaidRoutes />}> 
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/module/:id" element={<Module />} />
              <Route exact path="/modules/:id" element={<Modules />} />
             </Route> 
              <Route exact path="/payment" element={<Subscribe />} />
              </Route>

            
            </Routes>
          </AuthProvider>
        </Router>
      </Elements>
    </div>
  );
}
export default App;

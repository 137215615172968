import { Card } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./Tasks.css";

function Tasks() {
  const {id} = useParams(); 

  const [state, setState] = React.useState({
    steps: [], 
  })

  React.useEffect(() => {
    function handleFetch () { 
      const ref = doc(db, 'modules', id); 
      getDoc(ref).then((res) => {

        setState((state) => ({
          ...state, 
          steps: res.data().steps
        }))
      })
    } 
    handleFetch(); 
  }, [])


  return (
    <>

    {state.steps.map((step, ind) => (
 <Card className="tasks">
    <h1 className="step-num">{ind + 1}</h1>
      <h2>{step.title}</h2>


      <div dangerouslySetInnerHTML={{ __html: step.html }} />


    </Card>

    ))}


   



     </>
  );
}

export default Tasks;

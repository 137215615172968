import { Button, Card } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Funnel.css";
import funnel from "./funnel-no-background.png";

function Funnel() {
  const navigate = useNavigate();
  return (

    <div className="funnel-section">
        <img className="funnel-img" src={funnel} alt="hello" />
      

<div className="markers">
<div className="spacer" />
         
          <div className="marker">
            <Button style={{ paddingLeft:'20px', paddingRight:'20px', fontWeight:'bold', color:'black', backgroundColor:"#ade7b7"}} onClick={((e) => {navigate('/modules/Awareness')})}>Awareness</Button>
          </div>
          <div className="marker">
            <Button style={{ paddingLeft:'20px', paddingRight:'20px', fontWeight:'bold', color:'black', backgroundColor:"#ade7b7"}} onClick={((e) => {navigate('/modules/Interest')})}>Interest</Button>
          </div>
          <div className="marker">
            <Button style={{ paddingLeft:'20px', paddingRight:'20px', fontWeight:'bold', color:'black', backgroundColor:"#ade7b7"}} onClick={((e) => {navigate('/modules/Desire')})}>Desire</Button>
          </div>
          <div className="marker">
            <Button style={{ paddingLeft:'20px', paddingRight:'20px', fontWeight:'bold', color:'black', backgroundColor:"#ade7b7"}} onClick={((e) => {navigate('/modules/Action')})}>Action</Button>
          </div>
          <div className="marker">
            <Button style={{ paddingLeft:'20px', paddingRight:'20px', fontWeight:'bold', color:'black', backgroundColor:"#ade7b7"}} onClick={((e) => {navigate('/modules/Loyalty')})}>Loyalty</Button>
          </div>
          <div className="marker">
            <Button style={{ paddingLeft:'20px', paddingRight:'20px', fontWeight:'bold', color:'black', backgroundColor:"#ade7b7"}} onClick={((e) => {navigate('/modules/Advocacy')})}>Advocacy</Button>
          </div>
          <div className="spacer" />
        </div>


    </div>



    /*   <div className="section-fun">
        <img className="funnelimg" src={funnel} alt="hello" />

        
      </div> */
  );
}

export default Funnel;

import { Navigate, Outlet } from 'react-router-dom'
import { auth } from './firebase'

const PrivateRoutes = () => {
  let user = auth;  


return (
    auth.currentUser ? <Outlet/> : <Navigate to='/'/>
  )
}

export default PrivateRoutes;
import { CheckCircle } from "@mui/icons-material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { auth, db } from "../../../firebase";

function Completed({ id, obj }) {
  console.log(id);

  const [state, setState] = React.useState({
    complete: false,
  });

  React.useEffect(() => {
    function handleFetch() {
      const docRef = doc(db, "users", auth.currentUser.uid, "modules", id);
      getDoc(docRef)
        .then((e) => {
          if (e.exists()) {
            const { checked } = e.data();

            if (checked.length == obj.checklist.length) {
              // go furhter

              if (checked.includes(false)) {
                return null;
              } else {
                setState((state) => ({
                  ...state,
                  complete: true,
                }));
              }
            } else {
              return null;
            }
          } else {
            return null;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    handleFetch();
  }, [id]);

  return state.complete ? <CheckCircle style={{ color: "#ade7b7" }} /> : null;
}

export default Completed;

import React from "react";
import {
  CardElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Alert, Button, Card, CircularProgress, Snackbar } from "@mui/material";
import "./Subscribe.css";
import { getFunctions, httpsCallable } from "firebase/functions";
import { auth, db } from "../../firebase";
import { Autorenew } from "@mui/icons-material";
import { doc, getDoc } from "firebase/firestore";

function Subscribe() {
  const stripe = useStripe();
  const elements = useElements();

  const [state, setState] = React.useState({
    loading: false,
    active: false,
    cancelled: false,
    expires: 0,
    errorMsg: "",
    successMsg: "",
    errorOpen: false,
    successOpen: false,
  });

  React.useEffect(() => {
    async function handleFetch() {
      const ref = doc(db, "users", auth.currentUser.uid);
      await getDoc(ref)
        .then((res) => {
          if (res.exists) {
            setState((state) => ({
              ...state,
              active: res.data().active ? res.data().active : false,
              expires: res.data().expires ? res.data().expires : null,
              cancelled:
                typeof res.data().cancelled == "boolean"
                  ? res.data().cancelled
                  : null,
            }));
          } else {
            return null;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    handleFetch();
  }, []);

  function handleCardUpdate() {
    setState((state) => ({
      ...state,
      loading: true,
    }));
    stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      })
      .then(async function (result) {
        if (result.error) {
          setState((state) => ({
            ...state,
            loading: false,
            errorMsg: "Failed To Update Card...",
            errorOpen: true,
            successMsg: "",
            successOpen: false,
          }));
        } else {
          const functions = getFunctions();
          const cloudFunction = httpsCallable(functions, "AddCard");
          cloudFunction({
            payment_method: result.paymentMethod.id,
          })
            .then((res) => {
              setState((state) => ({
                ...state,
                loading: false,
                errorMsg: "",
                errorOpen: false,
                successMsg: "Successfully Updated Default Card...",
                successOpen: true,
              }));
            })
            .catch((err) => {
              setState((state) => ({
                ...state,
                loading: false,
                errorMsg: "Failed To Update Card...",
                errorOpen: true,
                successMsg: "",
                successOpen: false,
              }));
            });
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loading: false,
          errorMsg: "Failed To Update Card...",
          errorOpen: true,
          successMsg: "",
          successOpen: false,
        }));
      });
  }

  function handleSubscriptionStart() {
    setState((state) => ({
      ...state,
      loading: true,
    }));


    if (state.active === false && typeof state.cancelled !== 'boolean') {
        // new customer
    stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      }).then((result) => {

        if (result.error) {
            setState((state) => ({
                ...state,
                loading: false,
                errorMsg: "Failed To Start Subscription... Check Card Details...",
                errorOpen: true,
                successMsg: "",
                successOpen: false,
              }));

        } else {

      const functions = getFunctions();
      const cloudFunction = httpsCallable(functions, "startNewSubscription");
      cloudFunction({
        payment_method: result.paymentMethod.id,
      }).then((res) => {
          console.log(res);
  
          if (res.data.success) {
            setState((state) => ({
              ...state,
              loading: false,
              errorMsg: "",
              errorOpen: false,
              successMsg: "Successfully Started Subscription...",
              successOpen: true,
              active: true,
            }));
          } else {
            setState((state) => ({
              ...state,
              loading: false,
              errorMsg: "Failed To Start Subscription...",
              errorOpen: true,
              successMsg: "",
              successOpen: false,
            }));
          }
        })
        .catch((err) => {
          setState((state) => ({
            ...state,
            loading: false,
            errorMsg: "Failed To Start Subscription...",
            errorOpen: true,
            successMsg: "",
            successOpen: false,
          }));
          console.log(err);
        });
    }
    }).catch((err) => {
        setState((state) => ({
            ...state,
            loading: false,
            errorMsg: "Failed To Start Subscription...",
            errorOpen: true,
            successMsg: "",
            successOpen: false,
          }));
          console.log(err);

    })
    



    }  else {
        // restart subscription 

    const functions = getFunctions();
    const cloudFunction = httpsCallable(functions, "startSubscription");
    cloudFunction()
      .then((res) => {
        console.log(res);

        if (res.data.success) {
          setState((state) => ({
            ...state,
            loading: false,
            errorMsg: "",
            errorOpen: false,
            successMsg: "Successfully Started Subscription...",
            successOpen: true,
            active: true,
          }));
        } else {
          setState((state) => ({
            ...state,
            loading: false,
            errorMsg: "Failed To Start Subscription...",
            errorOpen: true,
            successMsg: "",
            successOpen: false,
          }));
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loading: false,
          errorMsg: "Failed To Start Subscription...",
          errorOpen: true,
          successMsg: "",
          successOpen: false,
        }));
        console.log(err);
      });


    }
  }

  function handleSubscriptionCancel() {
    setState((state) => ({
      ...state,
      loading: true,
    }));
    const functions = getFunctions();
    const cloudFunction = httpsCallable(functions, "cancelSubscription");
    cloudFunction()
      .then((res) => {
        if (res.data.success) {
          setState((state) => ({
            ...state,
            loading: false,
            errorMsg: "",
            errorOpen: false,
            successMsg: "Successfully Cancelled Subscription...",
            successOpen: true,
            active: false,
          }));
        } else {
          setState((state) => ({
            ...state,
            loading: false,
            errorMsg: "Failed To Cancel Subscription...",
            errorOpen: true,
            successMsg: "",
            successOpen: false,
          }));
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loading: false,
          errorMsg: "Failed To Cancel Subscription...",
          errorOpen: true,
          successMsg: "",
          successOpen: false,
        }));
      });
  }

  const paymentElementOptions = {
    layout: "tabs",
  };

  const red = {
    backgroundColor: "#ff7770",
    fontWeight: "bold",
    color: "white",
    letterSpacing: "1px",
  };

  const green = {
    backgroundColor: "#73ad70",
    fontWeight: "bold",
    color: "white",
    letterSpacing: "1px",
  };
  const orange = {
    backgroundColor: "#ff964f",
    fontWeight: "bold",
    color: "white",
    letterSpacing: "1px",
  };

  function formatDate() {
    console.log(state.expires * 1000);
    let date = new Date(state.expires * 1000);
    let localDate = date.toDateString();

    console.log(localDate);
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    let formatDate = `${day}/${month}/${year}`;

    return formatDate;
  }

  function HandleCancelled() {
    if (state.cancelled === true) {
      return <div className="expire-bar">{`Expires | ${formatDate()}`}</div>;
    } else if (state.cancelled === false) {
      return <div className="expire-bar">{`Renews | ${formatDate()}`}</div>;
    } else {
      return null;
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMsg: "",
      errorOpen: false,
      errorMsg: "",
    }));
  };

  const [vertical, setVertical] = React.useState("bottom");
  const [horizontal, setHorizontal] = React.useState("right");

  function HandleStatusReturn() {
    console.log(state.cancelled);
    if (state.active) {
      // active

    return(<Button style={green}>Active</Button>);
    } else if (state.active === false) {
      // could be cancelled or a new account
      if (typeof state.cancelled !== "boolean") {
        // new acc
        return(<Button style={orange}>Free 14 Day Trial</Button>)
      } else {
        return(<Button style={red}>Cancelled</Button>)
        // cancelled acc
      }
    } else {
        return(<Button style={green}>Active</Button>)
    }
  }

  return (
    <div className="subscribe-page">
      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="error">
          {state.errorMsg}
        </Alert>
      </Snackbar>

      <Card className="Subscribe-card">
        {state.loading && (
          <div className="loading">
            <CircularProgress style={{ color: "#ade7b7" }} />
          </div>
        )}
        <div className="status-bar">
          <HandleStatusReturn />
        </div>

        <HandleCancelled />

        <div className="subscription-banner">
          <Card className="subscription-card">
            <Card style={{ backgroundColor: "#ade7b7" }} className="iconCard">
              <Autorenew fontSize="large" />
            </Card>

            <div className="text-section">
              <div className="subtitle-msg">Basic Plan</div>
              <div className="subtitle-amount">$50 / Monthly</div>
            </div>
          </Card>
        </div>

        <CardElement
          disabled={state.loading}
          options={{ hidePostalCode: true }}
        />

        <div className="subscribe-buttons">
          <Button
            disabled={state.loading}
            className="subscribe-button"
            onClick={(e) => {
              handleCardUpdate();
            }}
          >
            Update Card
          </Button>
          <Button
            disabled={state.loading}
            className="subscribe-button"
            onClick={(e) => {
              if (state.active) {
                handleSubscriptionCancel();
              } else {
                handleSubscriptionStart();
              }
            }}
          >
            {state.active ? "Cancel Membership" : "Start Membership"}
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default Subscribe;

import { Navigate, Outlet } from 'react-router-dom'
import { auth, db } from './firebase'
import React from 'react'
import { doc, getDoc } from 'firebase/firestore';

const PaidRoutes = () => {
  let user = auth;  

  const [active, setActive] = React.useState(true); 

  React.useEffect(() => {
   async function handleFetch () {
    const docRef = doc(db, `/users/${user.currentUser.uid}`); 

    await getDoc(docRef).then((res) => {

      if (res.exists) {

        if (typeof res.data().active !== 'undefined') {
          console.log(res.data().cancelled)
      setActive(res.data().active)
        } else {
        console.log(res.data().active)
          setActive(false)
        }
      } else {
        setActive(false)
        console.log('invalid')
        return; 
      }
    }).catch((err) => {
      console.log(err)
    })
   }
   handleFetch(); 
  }, [auth.currentUser])
  


return (
  active ? <Outlet/> : <Navigate to='/payment'/>
  )
}

export default PaidRoutes;